/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      caiji: require('@/assets/face/caiji.png'),
      delFlag: '',
      faceSrc: '',
      serverId: '',
      show: false,
      disabled: false,
      dwCheck: ''
    };
  },
  watch: {
    disabled: {
      handler: function handler(newV) {
        var _this = this;

        if (newV) {
          setTimeout(function () {
            _this.disabled = false;
          }, 1000);
        }
      },
      deep: true
    }
  },
  methods: {
    getConfig: function getConfig() {
      var _this2 = this;

      var localUrl = window.location.href.split('#')[0];
      this.$utils.Tools.getEnv(function (res) {
        if (res === 0 || res === 1) {
          _this2.$api.Sys.getConfig(localUrl, _this2.$params.mode).then(function (response) {
            var code = response.code,
                msg = response.msg,
                data = response.data;

            if (+code !== _this2.$code.success) {
              _this2.$utils.Tools.toastShow(msg);
            } else {
              _this2.$utils.WeiXin.wxConfig(data);
            }
          }).catch(function () {
            _this2.$utils.Tools.httpError();
          });
        } else {
          _this2.$api.DingTalk.getDtConfig(localUrl).then(function (response) {
            var code = response.code,
                msg = response.msg,
                data = response.data;

            if (+code !== _this2.$code.success) {
              _this2.$utils.Tools.toastShow(msg);
            } else {
              _this2.$utils.DingTalk.getConfig(data);
            }
          }).catch(function () {
            _this2.$utils.Tools.httpError();
          });
        }
      });
    },
    chooseImage: function chooseImage(Serial) {
      var _this3 = this;

      this.$utils.Tools.getEnv(function (res) {
        if (res === 0 || res === 1) {
          _this3.wxChooseImage(Serial);

          _this3.disabled = true;
        } else {
          _this3.dtChooseImage();
        }
      });
    },
    dtChooseImage: function dtChooseImage() {
      var _this4 = this;

      this.$utils.DingTalk.uploadImage(function (res) {
        if (_this4.$utils.Tools.isArray(res)) {
          _this4.delFlag = '1';
          _this4.faceSrc = res[0];
          _this4.serverId = res[0];
        }
      }, 1);
    },
    wxChooseImage: function wxChooseImage() {
      var _this5 = this;

      setTimeout(function () {
        _this5.$utils.WeiXin.wxChooseImage(function (res) {
          if (res.length > 0) {
            _this5.uploadImage(res, 0);
          }
        }, 1, ['camera']);
      }, 1000);
    },
    uploadImage: function uploadImage(res, i) {
      var _this6 = this;

      this.$utils.WeiXin.wxUploadImage(function (serverId) {
        _this6.$api.Sys.getWxImageUrl(serverId, _this6.$params.mode).then(function (res) {
          var obj = {
            code: +res.code,
            serverId: serverId,
            id: _this6.$route.query.id
          }; //外勤打卡$route.query.id==3

          if (+_this6.$route.query.id == 3) {
            if (_this6.wqCheck == 1) {
              _this6.showFaceQuality(obj);
            } else if (_this6.wqCheck == 2) {
              _this6.checkUserFace(obj);
            } else {
              var param = _this6.$utils.Store.get('outSideId');

              param.userImage = obj.serverId;

              _this6.$utils.Store.set('outSideId', param);

              _this6.$router.go(-1);
            }
          } else {
            //定位考勤
            if (_this6.dwCheck == 1) {
              _this6.showFaceQuality(obj);

              return;
            } else if (_this6.dwCheck == 2) {
              _this6.checkUserFace(obj);

              return;
            } else {
              _this6.$utils.Store.set('locationId', obj);

              _this6.$router.go(-1);
            }
          }
        });
      }, res[i]);
    },

    /* 判读照片含有人脸 wqCheck=true表示开启外勤定位 */
    showFaceQuality: function showFaceQuality(item) {
      var _this7 = this;

      this.$api.Sys.faceQuality({
        userFacePhoto: item.serverId
      }).then(function (res) {
        _this7.isScan = false;

        if (+res.code == 600) {
          _this7.$utils.Store.set('locationId', item);

          if (_this7.$route.query.id == 3) {
            var param = _this7.$utils.Store.get('outSideId');

            param.userImage = item.serverId;

            _this7.$utils.Store.set('outSideId', param);
          }

          _this7.$router.go(-1);
        } else {
          //外勤打卡$route.query.id==3
          if (_this7.$route.query.id == 3) {
            var _param = _this7.$utils.Store.get('outSideId');

            _param.waiqindakaishib = true;
            _param.errMsg = res.msg;

            _this7.$utils.Store.set('outSideId', _param);
          } else {
            var _param2 = _this7.$utils.Store.get('locationId');

            _param2.dingweidakaishib = true;
            _param2.errMsg = res.msg;

            _this7.$utils.Store.set('locationId', _param2);
          }

          _this7.$router.go(-1); // Toast(res.msg)

        }
      });
    },
    getValue: function getValue() {
      var _this8 = this;

      this.$api.Attence.getKqSettting().then(function (res) {
        _this8.dwCheck = res.data.dwCheck;
        _this8.wqCheck = res.data.wqCheck; //获取人脸合身校验接口
        //dwCheck 0 不开启动 1 开启人脸照片  2 身份校验
      });
    },

    /* 身份核验 */
    checkUserFace: function checkUserFace(item) {
      var _this9 = this;

      var paramss = {
        userSerial: this.$utils.Store.getItem('userSerial'),
        userFacePhoto: item.serverId,
        channel: 0
      };
      this.$api.Sys.checkUserFace(paramss).then(function (res) {
        if (res.code == 600) {
          _this9.$utils.Store.set('locationId', item);

          if (_this9.$route.query.id == 3) {
            var param = _this9.$utils.Store.get('outSideId');

            param.userImage = item.serverId;

            _this9.$utils.Store.set('outSideId', param);
          }

          _this9.$router.go(-1); //  this.$router.go(-1)

        } else {
          //失败
          //外勤打卡$route.query.id==3
          if (_this9.$route.query.id == 3) {
            var _param3 = _this9.$utils.Store.get('outSideId');

            _param3.waiqindakaishib = true;
            _param3.errMsg = res.msg;

            _this9.$utils.Store.set('outSideId', _param3);
          } else {
            var _param4 = {
              dingweidakaishib: "",
              errMsg: ''
            };
            _param4.dingweidakaishib = true;
            _param4.errMsg = res.msg;

            _this9.$utils.Store.set('locationId', _param4);
          }

          _this9.$router.go(-1); //Toast(res.msg)

        }
      });
    }
  },
  created: function created() {
    this.userSerial = this.$utils.Store.getItem('userSerial');
    this.getConfig();
    this.getValue();
  },
  mounted: function mounted() {}
};